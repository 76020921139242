export const testListApi = '/list'
export const testStoreApi = '/store'
export const testUpdateApi = '/update'
export const testToggleStatusApi = '/toggle-status'
// warehouse type start

export const reportHeadingList = '/master-warehouse-report-heading/detail'
export const reportList = '/warehouse-service-report/warehouse-info'
export const receivedReportList = '/warehouse-product-receive-delivery/receive-report'
export const DeliveryReportList = '/warehouse-product-receive-delivery/delivery-report'
export const yearlyReportList = '/yearly-product-receive-delivery/report'
export const monthWiseReportList = '/monthly-product-receive/report'
export const warehouseWiseProductStorageReportApi = '/warehouse-service-report/warehouse-wise-product-storage'
export const warehouseWiseFarmerReport = '/warehouse-wise-farmer-info/report'
export const dateWiseFarmerStockReleaseReport = '/date-wise-farmer-stock-release/report'
export const warehouseRentCollectionReportApi = '/warehouse-service-report/warehouse-loan-collection'
export const warehouseDetailsListReport = '/warehouse-service-report/warehouse-details'
export const productStockReportApi = '/warehouse-service-report/product-stock'
export const documentCategoryupdate = ''
export const documentCategorystore = ''
