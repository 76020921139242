<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('sidebar.wareManagementSystem.warehouseReport') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('globalTrans.organization')"
                label-for="org_id"
            >
              <b-form-select
                  plain
                  v-model="search.org_id"
                  :options="organizationtList"
                  id="org_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('warehouse_report.division')"
                label-for="division_id"
            >
              <v-select name="division_id"
                    v-model="search.division_id"
                    label="text"
                    :options= divisionList
                    :reduce="item => item.value"
                    :placeholder="$t('globalTrans.select')"
                    />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('warehouse_config.region')"
                label-for="region_id"
            >
              <v-select name="region_id"
                v-model="search.region_id"
                label="text"
                :options= regionList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('warehouse_report.district')"
                label-for="district_id"
            >
              <v-select name="district_id"
            v-model="search.district_id"
            label="text"
            :options= districtList
            :reduce="item => item.value"
            :placeholder="$t('globalTrans.select')"
            />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('warehouse_report.upazilla')"
                label-for="upazilla_id"
            >
              <v-select name="upazilla_id"
                v-model="search.upazilla_id"
                label="text"
                :options= upazilaList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                :label="$t('globalTrans.union')"
                label-for="union_id"
            >
              <v-select name="union_id"
                v-model="search.union_id"
                label="text"
                :options= unionList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                label-for="commodity_group_id"
                :label="$t('warehouse_config.warehouse_type')"
            >
              <v-select name="warehouse_type_id"
                v-model="search.warehouse_type_id"
                label="text"
                :options= warehouseTypeList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
                class="row"
                label-cols-sm="3"
                label-cols-md="4"
                label-for="warehouse_id"
                :label="$t('warehouse_information.warehouse_name')"
            >
              <v-select name="warehouse_id"
                v-model="search.warehouse_id"
                label="text"
                :options= warehouseList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                v-if="!authUser.warehouse_id"
                />
                <b-form-select
                  plain
                  v-model="search.warehouse_id"
                  :options="warehouseList"
                  id="warehouse_id"
                  v-else
              />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-button type="button" class="float-right" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('sidebar.wareManagementSystem.warehouseReport') }}</h4>
          </template>
          <template v-slot:headerAction>
                <b-button class="btn_add_new mr-2" @click="pdfExport">
                  <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                </b-button>
                <export-excel
                  class="btn btn_add_new"
                  :data="dataCustomizeExcel"
                  :fields="json_fields"
                  :title="$t('sidebar.wareManagementSystem.warehouseReport')"
                  worksheet="Warehouse Report Sheet"
                  name="warehouse-report.xls">
                  <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                </export-excel>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
                <b-row v-show="!loading">
                 <b-col>
                    <list-report-head :base-url="warehouseServiceBaseUrl" :uri="reportHeadingList" :org-id="search.org_id">
                      {{ $t('warehouse_report.warehouse-wise-product-storage-report')}}
                    </list-report-head>
                  </b-col>
                </b-row>
                <b-row>
                    <b-col></b-col>
                </b-row>
              <b-row mt-5>
                <b-col md="12" class="table-responsive">
                  <b-table-simple class="table-bordered" responsive>
                    <b-tbody>
                      <b-tr>
                        <b-th width="25%">{{ $t('globalTrans.date') }} :</b-th>
                        <b-td width="25%">{{ getDateWithLanguage(currentDate) }}</b-td>
                        <b-th width="25%">{{ $t('globalTrans.division') }} :</b-th>
                        <b-td width="25%">{{ divisionName  }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th>{{ $t('globalTrans.region') }} :</b-th>
                        <b-td>{{ regionName  }}</b-td>
                        <b-th>{{ $t('globalTrans.district') }} :</b-th>
                        <b-td>{{ districtName  }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th>{{ $t('globalTrans.upazila') }} :</b-th>
                        <b-td>{{ upazilaName  }}</b-td>
                        <b-th>{{ $t('warehouse_report.warehouse_name') }} :</b-th>
                        <b-td>{{ warehouseName  }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <b-table bordered hover :items="wareReportList" :fields="columns">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(total_space)="data">
                      <span> {{ $n(data.item.total_space) }}</span>
                    </template>
                    <template v-slot:cell(actual_space)="data">
                      <span>{{ $n(data.item.actual_space) }}</span>
                    </template>
                      <template v-slot:cell(free_space)="data">
                          <span>{{ $n(data.item.free_space) }}</span>
                      </template>
                      <template v-slot:cell(total_amount)="data">
                          <span>{{ $n(data.item.total_amount) }}</span>
                      </template>
                      <template v-slot:cell(collection_amount)="data">
                        <span>{{ $n(data.item.collection_amount) }}</span>
                      </template>
                      <template v-slot:cell(due_amount)="data">
                        <span>{{ $n(data.item.due_amount) }}</span>
                      </template>
                      <template #custom-foot="">
                        <b-tr>
                          <b-th class="text-right" colspan="2"><span>{{ $t('sitePreference.grossTotal') }}</span></b-th>
                          <b-th class="text-right"><span>{{ $n(totalInfo.totalSpace) }}</span></b-th>
                          <b-th class="text-right"><span>{{ $n(totalInfo.actualSpace) }}</span></b-th>
                          <b-th class="text-right"><span>{{ $n(totalInfo.freeSpace) }}</span></b-th>
                          <b-th class="text-right"><span>{{ $n(totalInfo.totalAmount) }}</span></b-th>
                          <b-th class="text-right"><span>{{ $n(totalInfo.collectAmount) }}</span></b-th>
                          <b-th class="text-right"><span>{{ $n(totalInfo.dueAmount) }}</span></b-th>
                        </b-tr>
                      </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { warehouseServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList } from '@/modules/warehouse-service/report/api/routes'
import { warehouseReportApi } from '../../api/routes'
import excel from 'vue-excel-export'
import Vue from 'vue'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import ExportPdf from '@/Utils/export-pdf'
import ListReportHead from '@/components/custom/ListReportHead.vue'
// import { loggedInUser } from '@/Utils/common'
pdfMake.vfs = pdfFonts.pdfMake.vfs
Vue.use(excel)

const excelColumn = {
  SL: 'serial',
  Warehouse: 'warehouse',
  'Total Space': 'totalSpace',
  'Actual Space': 'actualSpace',
  'Free Space': 'freeSpace',
  'Total Amount': 'totalAmount',
  'Collect Amount': 'collectAmount',
  'Dues Amount': 'duesAmount'
}

const excelColumnBn = {
  'ক্রমিক নং': 'serial',
  গুদাম: 'warehouse',
  'মোট জায়গা': 'totalSpace',
  'আসল জায়গা': 'actualSpace',
  'মুক্ত জায়গা': 'freeSpace',
  'মোট পরিমাণ': 'totalAmount',
  'সংগৃহীত পরিমাণ': 'collectAmount',
  'বকেয়া পরিমাণ': 'duesAmount'
}

export default {
  name: 'UiDataTable',
  components: {
    ListReportHead
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        slOffset: 1
      },
      showData: false,
      showHeading: false,
      search: {
        org_id: 13,
        division_id: null,
        region_id: null,
        district_id: null,
        upazilla_id: null,
        union_id: null,
        warehouse_id: null,
        warehouse_type_id: null,
        year: 0,
        from_date: '',
        to_date: ''
      },
      organization: {},
      wareReportList: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      tempList: [],
      currentDate: '',
      region_name: '',
      division_name: '',
      district_name: '',
      upazilla_name: '',
      warehouse_name: '',
      region_name_bn: '',
      division_name_bn: '',
      district_name_bn: '',
      upazilla_name_bn: '',
      warehouse_name_bn: '',
      warehouseList: [],
      rows: [],
      warehouseServiceBaseUrl: warehouseServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      totalInfo: {
        totalSpace: 0,
        actualSpace: 0,
        freeSpace: 0,
        totalAmount: 0,
        collectAmount: 0,
        dueAmount: 0
      },
      loading: false,
      excelColumn: {
        SL: 'serial',
        Warehouse: 'warehouse',
        'Total Space': 'totalSpace',
        'Actual Space': 'actualSpace',
        'Free Space': 'freeSpace',
        'Total Amount': 'totalAmount',
        'Collect Amount': 'collectAmount',
        'Dues Amount': 'duesAmount'
      },
      excelColumnBn: {
        'ক্রমিক নং': 'serial',
        গুদাম: 'warehouse',
        'মোট জায়গা': 'totalSpace',
        'আসল জায়গা': 'actualSpace',
        'মুক্ত জায়গা': 'freeSpace',
        'মোট পরিমাণ': 'totalAmount',
        'সংগৃহীত পরিমাণ': 'collectAmount',
        'বকেয়া পরিমাণ': 'duesAmount'
      }
    }
  },
  computed: {
    json_fields: function () {
      return this.currentLocale === 'bn' ? excelColumnBn : excelColumn
    },
    isWareHouseUser () {
      return this.authUser.warehouse_id
    },
    dataCustomizeExcel () {
      var serial = 0
      const customizeData = this.wareReportList.map(item => {
        serial += 1
        if (this.currentLocale === 'bn') {
          item.serial = this.$n(serial)
          item.warehouse = item.warehouse_name_bn
          item.totalSpace = this.$n(item.total_space)
          item.actualSpace = this.$n(item.actual_space)
          item.freeSpace = this.$n(item.free_space)
          item.totalAmount = this.$n(item.total_amount)
          item.collectAmount = this.$n(item.collection_amount)
          item.duesAmount = this.$n(item.due_amount)
        } else {
          item.serial = this.$n(serial)
          item.warehouse = item.warehouse_name
          item.totalSpace = this.$n(item.total_space)
          item.actualSpace = this.$n(item.actual_space)
          item.freeSpace = this.$n(item.free_space)
          item.totalAmount = this.$n(item.total_amount)
          item.collectAmount = this.$n(item.collection_amount)
          item.duesAmount = this.$n(item.due_amount)
        }
        return Object.assign({}, item)
      })
      const item = {}
      item.serial = ''
      item.warehouse = this.currentLocale === 'en' ? 'GrossTotal :' : 'সর্ব মোট :'
      item.totalSpace = this.$n(this.totalInfo.totalSpace)
      item.actualSpace = this.$n(this.totalInfo.actualSpace)
      item.freeSpace = this.$n(this.totalInfo.freeSpace)
      item.totalAmount = this.$n(this.totalInfo.totalAmount)
      item.collectAmount = this.$n(this.totalInfo.collectAmount)
      item.duesAmount = this.$n(this.totalInfo.dueAmount)
      customizeData.push(item)
      return customizeData
    },
    warehouseTypeList: function () {
        return this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
    },
    organizationtList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList
    },
    fiscalList: function () {
      return this.$store.state.warehouse.fiscalYearList
    },
    regionList: function () {
        let regionList = this.$store.state.warehouse.regionList.filter(item => item.status === 0)
        if (this.isWareHouseUser) {
          regionList = regionList.filter(item => item.value === this.search.region_id)
        }
        return regionList
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    currentLocale () {
      return this.$i18n.locale
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('warehouse_warehouse_management.warehouse'), class: 'text-left' },
        { label: this.$t('warehouse_warehouse_management.totalSpace'), class: 'text-right' },
        { label: this.$t('warehouse_warehouse_management.actualSpace'), class: 'text-right' },
        { label: this.$t('warehouse_warehouse_management.freeSpace'), class: 'text-right' },
        { label: this.$t('warehouse_warehouse_management.totalAmount'), class: 'text-right' },
        { label: this.$t('warehouse_warehouse_management.collectAmount'), class: 'text-right' },
        { label: this.$t('warehouse_warehouse_management.duesAmount'), class: 'text-right' }
      ]

      let keys = []

      keys = [
        { key: 'index' },
        { key: this.currentLocale === 'bn' ? 'warehouse_name_bn' : 'warehouse_name' },
        { key: 'total_space' },
        { key: 'actual_space' },
        { key: 'free_space' },
        { key: 'total_amount' },
        { key: 'collection_amount' },
        { key: 'due_amount' }
      ]

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    regionName () {
      let regionName
      if (!this.search.region_id) {
        regionName = this.$t('globalTrans.all')
      } else {
        regionName = this.currentLocale === 'bn' ? this.region_name_bn : this.region_name
      }
      return regionName
    },
    divisionName () {
      let divisionName
      if (!this.search.division_id) {
        divisionName = this.$t('globalTrans.all')
      } else {
        const divisionList = this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        const divisionObject = divisionList.find(division => division.value === this.search.division_id)
        if (divisionObject) {
          divisionName = this.currentLocale === 'bn' ? divisionObject.text_bn : divisionObject.text
        }
      }
      return divisionName
    },
    districtName () {
      let districtName
      if (!this.search.district_id) {
        districtName = this.$t('globalTrans.all')
      } else {
        const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
        const districtObject = districtList.find(district => district.value === this.search.district_id)
        if (districtObject) {
          districtName = this.currentLocale === 'bn' ? districtObject.text_bn : districtObject.text
        }
      }
      return districtName
    },
    upazilaName () {
      let upazilaName
      if (!this.search.upazilla_id) {
        upazilaName = this.$t('globalTrans.all')
      } else {
        const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
        const upazilaObject = upazilaList.find(upazila => upazila.value === this.search.upazilla_id)
        if (upazilaObject) {
          upazilaName = this.currentLocale === 'bn' ? upazilaObject.text_bn : upazilaObject.text
        }
      }
      return upazilaName
    },
    warehouseName () {
      let warehouseName
      if (!this.search.warehouse_id) {
        warehouseName = this.$t('globalTrans.all')
      } else {
        const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
        const warehouseObject = warehouseList.find(warehouse => warehouse.value === this.search.warehouse_id)
        if (warehouseObject) {
          warehouseName = this.currentLocale === 'bn' ? warehouseObject.text_bn : warehouseObject.text
        }
      }
      return warehouseName
    },
    headerData () {
      return [
        [{ text: this.$t('globalTrans.date') }, { text: this.getDateWithLanguage(this.currentDate) }, { text: this.$t('globalTrans.division') }, { text: this.divisionName }, {}, {}, {}, {}],
        [{ text: this.$t('globalTrans.region') }, { text: this.regionName }, { text: this.$t('globalTrans.district') }, { text: this.districtName }, {}, {}, {}, {}],
        [{ text: this.$t('globalTrans.upazila') }, { text: this.upazilaName }, { text: this.$t('warehouse_report.warehouse_name') }, { text: this.warehouseName }, {}, {}, {}, {}]
      ]
    }
  },
  watch: {
    'search.region_id': function (newVal, oldVal) {
      if (!this.isWareHouseUser) {
            this.districtList = this.getRegionDistrictList(newVal)
        }
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      if (!this.isWareHouseUser) {
      this.warehouseList = this.getWarehouseName(newVal, 'UPZ')
      }
    },
    'search.union_id': function (newVal, oldVal) {
        if (!this.isWareHouseUser) {
          this.warehouseList = this.getWarehouseName(newVal, 'UP')
        }
    },
    'search.org_id': function (newVal, oldVal) {
      this.organization = this.organizationData(newVal)
    },
    'search.warehouse_type_id': function (newVal, oldVal) {
    if (!this.isWareHouseUser) {
      this.warehouseList = this.getWarehouseName(newVal, 'WT')
    }
    }
  },
  created () {
    this.getCurrentDate()
  },
  mounted () {
    core.index()
    if (this.authUser.warehouse_id) {
      const warehouseId = this.authUser.warehouse_id
      this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.search.warehouse_id = warehouseId
    }
    if (this.isWareHouseUser) {
        this.search.division_id = this.authUser.office_detail.division_id
        this.search.region_id = this.$store.state.warehouse.regionDetailList.find(item => item.district_id === this.authUser.office_detail.district_id).region_id
        this.search.district_id = this.authUser.office_detail.district_id
        this.districtList = this.$store.state.commonObj.districtList.filter(doc => doc.value === this.authUser.office_detail.district_id)
        this.search.upazilla_id = this.authUser.office_detail.upazilla_id ?? 0
        this.upazilaList = this.$store.state.commonObj.upazilaList.filter(doc => doc.value === this.authUser.office_detail.upazilla_id)
        this.search.union_id = this.authUser.office_detail.union_id ?? 0
         this.unionList = this.$store.state.commonObj.unionList.filter(doc => doc.value === this.authUser.office_detail.union_id)
        const warehouse = this.$store.state.warehouse.warehouseInfoList.find(doc => doc.office_id === this.authUser.office_detail.id)
        this.search.warehouse_id = warehouse.value
        this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(doc => doc.office_id === this.authUser.office_detail.id)
        this.search.warehouse_type_id = warehouse.warehouse_type_id
    }
  },
  methods: {
    searchData () {
      if (this.search.org_id !== 0 || this.search.division_id !== 0 || this.search.district_id !== 0 || this.search.upazilla_id !== 0) {
        this.loadData()
        this.organizationData()
      }
      const regionList = this.$store.state.warehouse.regionList.filter(item => item.status === 0)
      const divisionList = this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      const regionObject = regionList.find(region => region.value === this.search.region_id)
      const divisionObject = divisionList.find(division => division.value === this.search.division_id)
      const districtObject = districtList.find(district => district.value === this.search.district_id)
      const upazilaObject = upazilaList.find(upazilla => upazilla.value === this.search.upazilla_id)
      if (regionObject) {
        this.region_name = regionObject.text
        this.region_name_bn = regionObject.text_bn
      }
      if (divisionObject) {
        this.division_name = divisionObject.text
        this.division_name_bn = divisionObject.text_bn
      }
      if (districtObject) {
        this.district_name = districtObject.text
        this.district_name_bn = districtObject.text_bn
      }
      if (upazilaObject) {
        this.upazilla_name = upazilaObject.text
        this.upazilla_name_bn = upazilaObject.text_bn
      }
    },
    async organizationData () {
      const objectData = this.$store.state.commonObj.organizationProfileList
      const orglist = objectData.find(item => item.value === this.search.org_id)

      await RestApi.getData(warehouseServiceBaseUrl, reportHeadingList + '/' + this.search.org_id, { org_id: this.search.org_id }).then(response => {
        if (response.success) {
          const orgObject = response.data
          const orgData = {
            left_logo: orgObject.left_logo,
            right_logo: orgObject.right_logo,
            address: orgObject.address,
            address_bn: orgObject.address_bn,
            project_name: orgObject.project_name,
            project_name_bn: orgObject.project_name_bn
          }
          this.organization = Object.assign({}, orglist, orgData)
        }
      })
    },
    async loadData () {
      this.loading = true
      this.showData = true
      try {
        await RestApi.getData(warehouseServiceBaseUrl, warehouseReportApi, this.search).then(response => {
          if (response.success) {
            const wareReportList = response.data.map(item => {
              const warehouseList = this.$store.state.warehouse.warehouseInfoList
              const warehouseObject = warehouseList.find(warehouse => warehouse.value === item.warehouse_id)
              const warehouseData = {
                warehouse_name: warehouseObject !== undefined ? warehouseObject.text_en : '',
                warehouse_name_bn: warehouseObject !== undefined ? warehouseObject.text_bn : ''
              }
              return Object.assign({}, item, warehouseData)
            })
            this.wareReportList = wareReportList
            let totalSpaceSum, actualSpaceSum, freeSpaceSum, totalAmountSum, collectionAmountSum, dueAmountSum
            totalSpaceSum = actualSpaceSum = freeSpaceSum = totalAmountSum = collectionAmountSum = dueAmountSum = 0
            this.wareReportList.forEach(item => {
              totalSpaceSum += parseInt(item.total_space)
              actualSpaceSum += parseInt(item.actual_space)
              freeSpaceSum += parseInt(item.free_space)
              totalAmountSum += parseInt(item.total_amount)
              collectionAmountSum += parseInt(item.collection_amount)
              dueAmountSum += parseInt(item.due_amount)
            })
            this.totalInfo.totalSpace = totalSpaceSum
            this.totalInfo.actualSpace = actualSpaceSum
            this.totalInfo.freeSpace = freeSpaceSum
            this.totalInfo.totalAmount = totalAmountSum
            this.totalInfo.collectAmount = collectionAmountSum
            this.totalInfo.dueAmount = dueAmountSum

            this.showHeading = true
          }
        })
      } catch (error) {
        this.wareReportList = []
        this.$toast.error({
          title: 'Error',
          message: 'Please, try again.',
          color: '#FFE09B'
        })
      }
      this.loading = false
    },
    getRegionDistrictList (regionId = null) {
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          const dataObjectTemp = response.filter(document => document.region_id === regionId)
          const dataObject = dataObjectTemp.filter(item => item.status === 0)
          this.districtList = dataObject.map(obj => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
            } else {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text }
            }
          })
        }
      })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
        var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
        this.tempList = list
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
        this.tempList = list
      }

      if (Id && type === 'WT') {
        const filterData = this.tempList
        list = filterData.filter(warehouse => warehouse.warehouse_type_id === Id)
      }

      return list
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_warehouse_management.warehouse'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_warehouse_management.totalSpace'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_warehouse_management.actualSpace'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_warehouse_management.freeSpace'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_warehouse_management.totalAmount'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_warehouse_management.collectAmount'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouse_warehouse_management.duesAmount'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      rowData.unshift(labels)
      const finalRowData = this.headerData.concat(rowData)
      const reportTitle = this.$i18n.locale === 'en' ? 'Warehouse Report' : 'গুদাম প্রতিবেদন'
      const columnWids = ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto']
      ExportPdf.exportPdf(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', this.search.org_id, reportTitle, finalRowData, columnWids)
    },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          { key: this.currentLocale === 'bn' ? 'warehouse_name_bn' : 'warehouse_name' },
          { key: 'total_space' },
          { key: 'actual_space' },
          { key: 'free_space' },
          { key: 'total_amount' },
          { key: 'collection_amount' },
          { key: 'due_amount' }
        ]
      var serial = 0
      const listData = this.wareReportList.map(item => {
        serial += 1
        const rowData = keys.map((keyItem, index) => {
            if (['total_space', 'actual_space', 'free_space', 'total_amount', 'collection_amount', 'due_amount'].includes(keyItem.key)) {
              return { text: this.$n(item[keyItem.key]) }
            }
            if (keyItem.key === 'serial_no') {
              return { text: this.$n(serial) }
            }

          return { text: item[keyItem.key] }
        })

        return rowData
      })

      const total = [
        this.currentLocale === 'en' ? { text: 'GrossTotal :' } : { text: 'সর্ব মোট :' },
        { text: '' },
        { text: this.$n(this.totalInfo.totalSpace) },
        { text: this.$n(this.totalInfo.actualSpace) },
        { text: this.$n(this.totalInfo.freeSpace) },
        { text: this.$n(this.totalInfo.totalAmount) },
        { text: this.$n(this.totalInfo.collectAmount) },
        { text: this.$n(this.totalInfo.dueAmount) }
      ]
      listData.push(total)
      return listData
    },
    getCurrentDate () {
      var today = new Date()
      var dd = String(today.getDate()).padStart(2, '0')
      var mm = String(today.getMonth() + 1).padStart(2, '0')
      var yyyy = today.getFullYear()

      this.currentDate = dd + '/' + mm + '/' + yyyy
    },
    getDateWithLanguage (date) {
      return this.$n(date.split('/')[0], { useGrouping: false }) + '/' + this.$n(date.split('/')[1], { useGrouping: false }) + '/' + this.$n(date.split('/')[2], { useGrouping: false })
    },
    divisionNameInfo () {
      let divisionName
      if (!this.search.division_id) {
        divisionName = this.$t('globalTrans.all')
      } else {
        const divisionList = this.$store.state.commonObj.divisionList
        const divisionObject = divisionList.find(division => division.value === this.search.division_id)
        if (divisionObject) {
          divisionName = this.currentLocale === 'bn' ? divisionObject.text_bn : divisionObject.text
        }
      }
      return divisionName
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
